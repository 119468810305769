<template>
  <div class="row">
    <treeselect
      @select="selectBatches($event)"
      v-model="batchId"
      :key="counter"
      style="display: flex"
      :showCount="true"
      @deselect="unselectBatches($event)"
      :multiple="multipleSelect"
      :disable-branch-nodes="!multipleSelect"
      :clearable="multipleSelect"
      :options="newBatchList"
      :openDirection="bottom"
      :max-height="300"
      :limit="2"
      placeholder="Select batches"
    >
      <template v-slot:option-label="{ node, shouldShowCount, count }">
        <label style="font-size: 16px; font-weight: 600; color: black">
          {{ node.label }}
          <span v-if="shouldShowCount" style="font-size: 16px; font-weight: 600; color: black"
            >({{ count }})</span
          >
        </label>
      </template>
      <template v-slot:value-label="{ node }">
        <label style="font-size: 16px; font-weight: 600; color: black"> {{ node.label }} </label>
      </template>
    </treeselect>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import Mobileapi from "../Mobileapi";

export default {
  components: { Treeselect },
  data() {
    return {
      selectedBatchesList: [],
      newBatchList: [],
      counter: 0,
      batchId: [],
    };
  },
  props: {
    courseId: Number,
    multipleSelect: Boolean,
    sendFullData: Boolean,
    selectedBatchId: Number,
  },
  created() {
    const obj = { isEditMode: false, courseId: this.courseId };
    Mobileapi.getParentChildBatchesForAffiliation(obj, (response) => {
      this.newBatchList = response.data;
      if (this.selectedBatchId > 0) {
        this.batchId.push(this.selectedBatchId);
      }
    });
    this.counter += 1;
  },
  methods: {
    selectBatches(node) {
      if (!this.multipleSelect) {
        this.selectedBatchesList = [];
      }
      if (node != null) {
        if (node.isAffiliation) {
          if (node.children != null && node.children.length > 0) {
            const batchIds = this.getAllBatchIds(node.children);
            if (batchIds != null && batchIds.length > 0) {
              batchIds.forEach((element) => {
                this.selectedBatchesList.push(element);
              });
            }
          }
        } else if (node.isCourse) {
          const batchIds = this.getAllBatchIds(node.children);
          if (batchIds != null && batchIds.length > 0) {
            batchIds.forEach((element) => {
              this.selectedBatchesList.push(element);
            });
          }
        } else {
          this.selectedBatchesList.push(node.id);
        }
      }
      this.$emit("valuesChanged", this.selectedBatchesList);
      if (this.sendFullData && !this.multipleSelect) {
        this.$emit("nameChanged", node);
      }
    },
    unselectBatches(node) {
      if (node != null) {
        if (node.isAffiliation) {
          if (node.children != null && node.children.length > 0) {
            const batchIds = this.getAllBatchIds(node.children);
            if (batchIds != null && batchIds.length > 0) {
              batchIds.forEach((element) => {
                const index = this.selectedBatchesList.findIndex(
                  (a) => Number(a) === Number(element),
                );
                if (index >= 0) {
                  this.selectedBatchesList.splice(index, 1);
                }
              });
            }
          }
        } else if (node.isCourse) {
          const batchIds = this.getAllBatchIds(node.children);
          if (batchIds != null && batchIds.length > 0) {
            batchIds.forEach((element) => {
              const index = this.selectedBatchesList.findIndex(
                (a) => Number(a) === Number(element),
              );
              if (index >= 0) {
                this.selectedBatchesList.splice(index, 1);
              }
            });
          }
        } else {
          const index = this.selectedBatchesList.findIndex((a) => Number(a) === Number(node.id));
          if (index >= 0) {
            this.selectedBatchesList.splice(index, 1);
          }
        }
        this.$emit("valuesChanged", this.selectedBatchesList);
      }
    },
    getAllBatchIds(nodes) {
      const stack = [...nodes];
      const batchIds = [];

      while (stack.length > 0) {
        const node = stack.pop();

        if (node.isBatch) {
          batchIds.push(node.id);
        }

        if (node.children) {
          stack.push(...node.children);
        }
      }

      return batchIds;
    },
  },
};
</script>

<style scoped>
.head {
  margin-left: 550px;
  margin-top: 19px;
  font-size: 25px;
}

.labelClassName {
  font-size: 25px !important;
  font-weight: bold !important;
}

.dic {
  margin-left: 300px;
  margin-top: 18.5px;
  border-radius: 1px solid;
  margin-bottom: 24.5px;
  width: 600px;
}

.card {
  left: 300px;
  width: 600px;
  height: 270px;
  border-radius: 5px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.submitbutton {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 28px;
  width: 144px;
  height: 38px;
}

.dtitle {
  font-size: 18px;
  color: #333333;
}
</style>
