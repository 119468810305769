<template>
  <div class="effort-analysis flex bg-green">
    <div class="left-section white card-border card-radius">
      <div class="flex flex-between">
        <h2 class="secondary-header">Effort Analysis</h2>
        <!-- <div>
                <div v-if="showClassChangeOption === true" class="flex" style="cursor: pointer" @click="changeClass()"><span class="flex fs-12 fw-600" style="margin-top:7px;color: #3751FF;padding-right: 7px;">Class {{this.effortClassId}}</span><img style="height: 16px; width: 16px;margin-top: 8px;" :src="require(`../assets/class-change.svg`)" /></div>
            </div> -->
      </div>
      <div class="col l4" style="margin-top: 10px" v-click-outside="onClickOutside">
        <BatchDropdown
          :key="this.effortBatchId"
          :courseId="null"
          :selectedBatchId="this.effortBatchId"
          :multipleSelect="false"
          :sendFullData="true"
          @nameChanged="batchNameChanged($event)"
        >
        </BatchDropdown>
      </div>
      <div class="relative analysis-period">
        <h2 class="secondary-header left-card">Analysis Period</h2>
        <div class="flex flex-wrap">
          <a
            class="gray-btn"
            style="margin-bottom: 10px; margin-right: 10px; height: 30px; line-height: 30px"
            :class="selectedPeriod === period.displayPeriod ? 'active' : ''"
            @click="filterByPeriod(period.displayPeriod)"
            v-for="(period, index) in analysisPeriod"
            :key="index"
            >{{ period.displayPeriod }}</a
          >
        </div>
        <div v-if="showCustomDate">
          <div
            class="card-border card-radius flex items-center flex-between"
            style="padding: 14px; height: 36px; width: 268px; margin-top: 10px"
          >
            <div class="flex items-center">
              <p class="fs-13 fw-600 skyblue-text" style="margin-right: 9.67px">{{ startDate }}</p>
              <img
                :src="require(`../assets/calendar (1) 1.svg`)"
                alt=""
                class="pointer"
                @click="showStartCalendar = !showStartCalendar"
              />
            </div>
            <p class="fs-13 fw-400 dark-text">-</p>
            <div class="flex items-center">
              <p class="fs-13 fw-600 skyblue-text" style="margin-right: 9.67px">{{ endDate }}</p>
              <img
                :src="require(`../assets/calendar (1) 1.svg`)"
                alt=""
                class="pointer"
                @click="showEndCalendar = !showEndCalendar"
              />
            </div>
            <img
              :src="require(`../assets/Vector4.svg`)"
              class="pointer"
              alt=""
              @click="showRangeCalendar = !showRangeCalendar"
            />
          </div>
          <p class="red-text" v-if="isCustomDateErr">Start date must be less than end date.</p>
          <v-calendar
            class="calender-box absolute"
            :attributes="attributes"
            @dayclick="selectStartDate"
            title-position="left"
            is-expanded
            is-range
            v-if="showStartCalendar"
          ></v-calendar>
          <v-calendar
            class="calender-box absolute"
            :attributes="attributes"
            @dayclick="selectEndDate"
            title-position="left"
            is-expanded
            is-range
            v-if="showEndCalendar"
          ></v-calendar>
          <v-date-picker
            class="calender-box absolute"
            v-model="range"
            :attributes="attributes"
            @dayclick="selectDateRange"
            title-position="left"
            is-expanded
            is-range
            v-if="showRangeCalendar"
          >
          </v-date-picker>
        </div>
      </div>
      <div class="student-filter" v-if="Object.values(this.effortAnalysisResponse).length > 0">
        <h2 class="secondary-header left-card">Student Filters</h2>
        <div class="flex flex-wrap">
          <a
            class="gray-btn student-btn"
            :class="effortfilterData.isDefault ? 'active' : ''"
            v-for="(effortfilterData, index) in EffortAnalysisFilter"
            :key="index"
            @click="filterByStudent(effortfilterData)"
            >{{ effortfilterData.displayName }}</a
          >
        </div>
      </div>
      <div class="subject-filter" v-if="Object.values(this.effortAnalysisResponse).length > 0">
        <h2 class="secondary-header left-card" style="margin-bottom: 2px">Subject Filters</h2>
        <div class="flex flex-wrap">
          <!-- <a class="gray-btn" style="margin-right: 10px; height: 30px; line-height: 30px; margin-top: 10px;" :class="selectedSubName === null ? 'active' : ''" @click="allSubject()">All</a> -->
          <a
            class="gray-btn"
            style="margin-right: 10px; height: 30px; line-height: 30px; margin-top: 10px"
            :class="subject.subjectName === selectedSubName ? 'active' : ''"
            v-for="subject in subjectList"
            :key="subject.subjectId"
            @click="selectSubject(subject.subjectName, subject.subjectId)"
            >{{ subject.subjectName }}</a
          >
        </div>
      </div>
      <img :src="require(`../assets/Group 92294.svg`)" alt="" style="margin-top: 31px" />
    </div>
    <div class="middle-section">
      <div class="no-record-box" v-if="isDataNotFound">
        <img :src="require(`../assets/no-result-found.png`)" alt="" style="max-width: 180px" />
        <h3
          class="dark-text fs-18 fw-600"
          style="line-height: 30px; padding-bottom: 15px; opacity: 0.4"
        >
          No records available for "{{ selectedBatchName }}".
        </h3>
      </div>
      <div
        class="white"
        v-if="!isDataNotFound"
        style="
          border: 1px solid #e0e4f0;
          box-shadow: 2.61947px 2.61947px 6.54867px rgba(100, 44, 144, 0.06);
          border-radius: 4px;
          margin-bottom: 16px;
        "
      >
        <div
          class="header"
          style="
            display: flex;
            border-bottom: 1px solid #e6e6e6;
            padding-left: 16px;
            margin-bottom: 8px;
          "
        >
          <div class="fs-12 fw-700 summaryHeader" style="width: 126px; color: #642c90">Summary</div>
          <div class="fs-12 fw-600 summaryHeader" style="width: 87px">Total</div>
          <div class="fs-12 fw-600 summaryHeader" style="width: 122px">Time Attempted</div>
          <div class="fs-12 fw-600 summaryHeader" style="width: 98px">Accuracy</div>
          <div class="fs-12 fw-600 summaryHeader" style="width: 104px">Expertise</div>
        </div>
        <div class="body" v-for="(data, index) in this.EffortAnalysisSummary" :key="index">
          <div class="flex" style="padding-left: 16px; padding-right: 30px">
            <div class="fs-12 fw-700" style="width: 126px; padding-bottom: 15px">
              {{ data.subjectName }}
            </div>
            <div
              class="summaryBody fs-13 fw-600"
              style="width: 87px"
              :style="{ color: data.Attempted.color }"
            >
              {{ data.Attempted.value }}
            </div>
            <div
              class="summaryBody fs-13 fw-600"
              style="width: 122px"
              :style="{ color: data.TimeTaken.color }"
            >
              {{ data.TimeTaken.value }}
            </div>
            <div
              class="summaryBody fs-13 fw-600"
              style="width: 98px"
              :style="{ color: data.Accuracy.color }"
            >
              {{ data.Accuracy.value }}
            </div>
            <div class="progress1" style="width: 84px; margin-top: 7px">
              <div
                class="progress-bar1"
                role="progressbar"
                :style="`width: ${parseInt((data.gaugeValue * 100).toFixed(0))}%`"
                v-bind:aria-valuenow="parseInt((data.gaugeValue * 100).toFixed(0))"
                aria-valuemin="0"
                aria-valuemax="100"
                :class="[
                  {
                    transparent:
                      parseInt((data.gaugeValue * 100).toFixed(0)) == 0 ||
                      parseInt((data.gaugeValue * 100).toFixed(0)) == '',
                  },
                  {
                    red:
                      parseInt((data.gaugeValue * 100).toFixed(0)) <= 50 &&
                      parseInt((data.gaugeValue * 100).toFixed(0)) > 0,
                  },
                  {
                    green:
                      parseInt((data.gaugeValue * 100).toFixed(0)) <= 100 &&
                      parseInt((data.gaugeValue * 100).toFixed(0)) > 50,
                  },
                ]"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-border card-radius card-shadow white" v-if="!isDataNotFound">
        <div class="data-header flex" style="border-bottom: 1px solid #e0e4f0; padding: 15px 30px">
          <h3 class="fs-12 fw-400 dark-text" style="opacity: 0.7">Name</h3>
          <h3 class="fs-12 fw-400 dark-text" style="opacity: 0.7">Subjects</h3>
          <h3 class="fs-12 fw-400 dark-text" style="opacity: 0.7">Expertise</h3>
        </div>
        <div style="padding: 0 15px 15px; max-height: 810px" class="scroll-verticle">
          <div
            class="data-contents"
            :class="[
              { active: studentDataIndex === index },
              { hide: effortData.activityList.length === 0 },
            ]"
            v-for="(effortData, index) in EffortAnalysisData"
            :key="index"
          >
            <div style="padding: 0 20px 13px 15px">
              <div
                class="flex items-center flex-wrap user-card"
                :class="{ active: studentDataIndex === index }"
              >
                <div class="flex items-center" style="max-width: 100%; width: 188px">
                  <ProfileAvatar
                    :username="effortData.userName.charAt(0)"
                    class="leader-avatar"
                    style="margin-left: 15px; border: none; width: 20px"
                  ></ProfileAvatar>
                  <h3
                    class="truncate fs-12 black-text fw-600"
                    style="white-space: nowrap; max-width: 92px; line-height: inherit"
                  >
                    {{ effortData.userName }}
                  </h3>
                </div>
                <p
                  class="fs-12 fw-400 dark-text user-subject"
                  style="width: 100%; max-width: 163px"
                >
                  {{ formateSubject(effortData.subjectNames) }} <span class="skyblue-text"></span>
                </p>
                <div class="progress1 flex flex-between">
                  <div
                    class="progress-bar1"
                    role="progressbar"
                    :style="`width: ${parseInt((effortData.gaugeValue * 100).toFixed(0))}%`"
                    v-bind:aria-valuenow="parseInt((effortData.gaugeValue * 100).toFixed(0))"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :class="[
                      {
                        transparent:
                          parseInt((effortData.gaugeValue * 100).toFixed(0)) == 0 ||
                          parseInt((effortData.gaugeValue * 100).toFixed(0)) == '',
                      },
                      {
                        red:
                          parseInt((effortData.gaugeValue * 100).toFixed(0)) <= 50 &&
                          parseInt((effortData.gaugeValue * 100).toFixed(0)) > 0,
                      },
                      {
                        green:
                          parseInt((effortData.gaugeValue * 100).toFixed(0)) <= 100 &&
                          parseInt((effortData.gaugeValue * 100).toFixed(0)) > 50,
                      },
                    ]"
                  ></div>
                </div>
                <!-- <div style="margin-left: 24px;"><i class="material-icons fs-15 black-text" style="cursor:pointer;">expand_less</i>
                              <i class="material-icons fs-15 black-text" style="cursor:pointer;">expand_more</i></div> -->
              </div>
              <div
                class="flex flex-wrap items-center flex-between"
                style="padding-left: 29px; margin-top: 18px"
              >
                <div class="flex items-center metrics" style="width: 329px">
                  <p class="fs-13 fw-600 flex items-center">
                    <QuestionIconSvg
                      :bgColor="effortData.Attempted?.color"
                      style="margin-right: 7px"
                    />
                    <span :style="`color: ${effortData.Attempted?.color}`" style="width: 54px">{{
                      effortData.Attempted?.value
                    }}</span>
                  </p>
                  <p class="fs-13 fw-600 flex items-center">
                    <EffortSvg :bgColor="effortData.TimeTaken?.color" style="margin-right: 9px" />
                    <span
                      style="line-height: 18px; width: 54px"
                      :style="`color: ${effortData.TimeTaken?.color}`"
                      >{{ effortData.TimeTaken?.value }}</span
                    >
                  </p>
                  <p class="fs-13 fw-600 flex items-center">
                    <SpeedSvg :bgColor="effortData.AvgTime?.color" style="margin-right: 8px" />
                    <span
                      class="speedSvg"
                      style="line-height: 18px; width: 80px"
                      :style="`color: ${effortData.AvgTime?.color}`"
                      >{{ effortData.AvgTime?.value }}</span
                    >
                  </p>
                  <p class="fs-13 fw-600 flex items-center">
                    <AccuracySvg :bgColor="effortData.Accuracy?.color" style="margin-right: 9px" />
                    <span
                      style="line-height: 18px"
                      :style="`color: ${effortData.Accuracy?.color}`"
                      >{{ effortData.Accuracy?.value }}</span
                    >
                  </p>
                </div>
                <a
                  class="coveragebuttons view-btn"
                  style="width: 69px"
                  @click="showStudentData(index)"
                  >{{ studentDataIndex === index ? "Hide" : "View" }}</a
                >
              </div>
            </div>
            <div
              style="background-color: #f3f5fc; padding: 0 10px 15px 34px; border-radius: 4px"
              v-if="studentDataIndex === index"
            >
              <div class="data-header flex" style="padding: 10px">
                <h3 class="fs-12 fw-400 dark-text" style="opacity: 0.7; width: 161px">
                  Date & Time
                </h3>
                <h3 class="fs-12 fw-400 dark-text" style="opacity: 0.7; width: 108px">Subject</h3>
                <h3 class="fs-12 fw-400 dark-text" style="opacity: 0.7">Chapter</h3>
              </div>
              <div class="scroll-verticle" style="max-height: 300px">
                <div>
                  <div
                    class="card-radius inner-box"
                    v-for="(activityData, index2) in effortData.activityList"
                    :key="index2"
                    @click="showRemark(index2)"
                  >
                    <div class="flex items-center inner-box-detail">
                      <p class="fs-12 fw-600 skyblue-text" style="min-width: 161px">
                        {{ activityData.startedOn }}
                      </p>
                      <p class="fs-12 fw-600 dark-text" style="min-width: 108px">
                        {{ formateSubject(activityData.subjectNames) }}
                      </p>
                      <p class="fs-12 fw-600 dark-text truncate">
                        {{ formateSubject(activityData.chapterNames) }}
                      </p>
                    </div>
                    <div class="flex flex-wrap items-center flex-between" style="margin-top: 10px">
                      <div class="flex items-center flex-wrap metrics">
                        <p class="fs-13 fw-600 flex items-center">
                          <QuestionIconSvg
                            :bgColor="activityData.Attempted.color"
                            style="margin-right: 7px"
                          />
                          <span
                            :style="`color: ${activityData.Attempted.color}`"
                            style="width: 54px"
                            >{{ activityData.Attempted.value }}</span
                          >
                        </p>
                        <p class="fs-13 fw-600 flex items-center">
                          <EffortSvg
                            :bgColor="activityData.TimeTaken.color"
                            style="margin-right: 9px"
                          />
                          <span
                            style="line-height: 18px; width: 54px"
                            :style="`color: ${activityData.TimeTaken.color}`"
                            >{{ activityData.TimeTaken.value }}</span
                          >
                        </p>
                        <p class="fs-13 fw-600 flex items-center">
                          <SpeedSvg
                            :bgColor="activityData.AvgTime.color"
                            style="margin-right: 8px"
                          />
                          <span
                            class="speedSvg"
                            style="line-height: 18px; width: 80px"
                            :style="`color: ${activityData.AvgTime.color}`"
                            >{{ activityData.AvgTime.value }}</span
                          >
                        </p>
                        <p class="fs-13 fw-600 flex items-center">
                          <AccuracySvg
                            :bgColor="activityData.Accuracy.color"
                            style="margin-right: 9px"
                          />
                          <span
                            style="line-height: 18px"
                            :style="`color: ${activityData.Accuracy.color}`"
                            >{{ activityData.Accuracy.value }}</span
                          >
                        </p>
                      </div>
                      <!-- <div> -->
                      <a
                        class="coveragebuttons skyblue-text report-btn"
                        style="width: 84px; background: #f3f5fc !important; line-height: 22px"
                        @click="viewReport(activityData.activityInstanceId, effortData.userName)"
                        >View Report</a
                      >
                      <!-- </div> -->
                    </div>
                    <!-- <div class="remark-box" v-if="viewRemarkIndex === index2 && isRemarkShow">
                                        <p class="fs-12 fw-600 dark-text" style="margin-bottom: 6px;">Remarks</p>
                                        <p class="fs-10 fw-400 dark-text">This practice session had less than 20 attempted questions.
                                            Meaningful insights may not be available.</p>
                                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-section">
      <div class="right-section-details">
        <div class="" v-if="this.AvReport.length > 0">
          <h1 class="fs-18 fw-700 dark-text" style="margin-bottom: 12px">Events & Highlights</h1>
          <Flicking
            :options="{ align: 'prev', circular: true }"
            :plugins="plugins"
            class="flex items-end card-radius"
            style="margin-bottom: 10px"
          >
            <div v-for="(img, index) in AvReport" :key="index">
              <div class="slideshow-container flex card-shadow card-radius card-border">
                <div class="relative" style="padding: 15px; width: 100%">
                  <div class="flex flex-between items-start">
                    <h2
                      class="fw-600 fs-13"
                      style="color: #ffffff !important"
                      :title="img.ReportName"
                    >
                      {{
                        img.ReportName
                          ? img.ReportName.length > 30
                            ? img.ReportName.slice(0, 30) + "..."
                            : img.ReportName
                          : null
                      }}
                    </h2>
                    <img
                      v-if="img.CardType === 'AVReport'"
                      :src="require(`../assets/video 2.svg`)"
                      alt=""
                      width="24"
                    />
                  </div>
                  <p
                    v-if="img.CardType === 'AVReport'"
                    class="fs-12 fw-600 white-text"
                    style="opacity: 0.7"
                  >
                    {{ img.SubjectName }}
                  </p>
                  <p
                    v-if="img.CardType === 'AffiliationReport'"
                    class="fs-12 fw-600 white-text"
                    style="opacity: 0.7"
                  >
                    Download effort analysis report for the institute.
                  </p>
                  <p
                    v-if="img.CardType === 'AVReport'"
                    class="white-text fs-11 fw-400"
                    style="opacity: 0.7"
                  >
                    {{ formatDate(img.CreatedOn) }}
                  </p>
                  <a
                    v-if="img.CardType === 'AVReport'"
                    class="coveragebuttons fs-10 fw-600"
                    style="
                      width: 83px;
                      height: 20.03px;
                      margin-top: 10px;
                      line-height: 20.03px;
                      display: inline-block;
                    "
                    @click="watchVideo(img.VimeoURL)"
                    >Watch Video</a
                  >
                  <a
                    v-if="img.CardType === 'AffiliationReport'"
                    class="coveragebuttons fs-10 fw-600"
                    style="
                      width: 83px;
                      height: 20.03px;
                      margin-top: 25px;
                      line-height: 20.03px;
                      display: inline-block;
                    "
                    @click="showPdf()"
                    >Download</a
                  >
                  <img class="class-image" :src="require(`../assets/Ellipse 419.svg`)" />
                </div>
              </div>
            </div>
            <template #viewport>
              <div class="flicking-pagination"></div>
            </template>
          </Flicking>
        </div>
        <div class="schedule_event">
          <h1 class="fs-18 fw-700 dark-text top-header" style="margin-bottom: 10px">
            Schedule an Event
          </h1>
          <div class="schedule-card card-border card-radius card-shadow">
            <img :src="require(`../assets/schedule-img.svg`)" alt="schedule-img" />
            <a
              class="coveragebuttons"
              style="background: #f3f5fc !important; color: #3751ff !important"
              @click="scheduleClass()"
              >Schedule Extra Class</a
            >
            <a
              class="coveragebuttons"
              style="background: #f3f5fc !important; color: #3751ff !important"
              @click="CreateAssignment()"
              >Create Assignment</a
            >
            <a class="coveragebuttons" @click="createTest()">Create test</a>
          </div>
        </div>
      </div>
    </div>
    <Modal :show="showClassSelectionModal" :showHeader="false" v-if="classList.length > 0">
      <template v-slot:body>
        <div style="padding: 20px">
          <div style="display: flex; justify-content: center">
            <img class="center-align" :src="require(`../assets/school.png`)" />
          </div>
          <p class="center-align modal-select" style="margin: 10px 0 20px 0; font-size: 16px">
            Kindly select class for <b>{{ this.selectedBatchName }}</b>
          </p>
          <div class="center" style="width: 450px; display: flex; justify-content: space-around">
            <button
              v-for="(cls, index) in classList"
              :key="index"
              class="modal-button pointer"
              :class="{ selected: effortClassId === cls.id || effortClassId === cls.Id }"
              @click="onClassChange(cls.id || cls.Id)"
            >
              {{ cls.DisplayName || cls.displayName }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal2
      :show="showAvVideo"
      :showHeader="false"
      :showCloseBtn="false"
      @close="showAvVideo = false"
    >
      <template v-slot:body>
        <div class="flex flex-center video-head">
          <p
            @click="
              showAvVideo = false;
              avVideoUrl = '';
            "
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons video-close">close</span>
          </p>
        </div>
        <iframe
          :src="avVideoUrl"
          frameborder="0"
          allow=" fullscreen; picture-in-picture"
          allowfullscreen
          class="videoModal"
        ></iframe>
      </template>
    </Modal2>
    <Modal3
      :show="showPdfPopup"
      :showHeader="false"
      :showCloseBtn="false"
      @close="showPdfPopup = false"
    >
      <template v-slot:body>
        <div class="pdf-box">
          <div class="flex flex-between">
            <h1 class="title-effort-analysis">Effort Analysis report</h1>
            <img
              :src="require(`../assets/cancel14.svg`)"
              class="close-button"
              @click="showPdfPopup = false"
            />
          </div>
          <div>
            <div class="flex flex-wrap flex-class">
              <div>
                <h1 class="select-period">Select Period</h1>
                <select
                  class="select-period-box"
                  name="timeForPdf"
                  v-model="selectedTime"
                  @change="optionSelected"
                >
                  <option value="last week" selected>Last Week</option>
                  <option value="last month">Last Month</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              <div v-if="selectedTime === 'custom'" style="margin-right: 10px">
                <h1 class="select-custom-date">StartDate</h1>
                <input
                  type="date"
                  class="select-date-box"
                  name="startDate"
                  v-model="customStartDate"
                  @change="showCalendar()"
                />
              </div>
              <div v-if="selectedTime === 'custom'">
                <h1 class="select-custom-date">EndDate</h1>
                <input
                  type="date"
                  :max="this.maxDate"
                  class="select-date-box"
                  name="endDate"
                  v-model="customEndDate"
                  @change="showCalendar()"
                />
              </div>
            </div>
            <div class="flex">
              <button
                v-if="generateButton"
                class="generate-button b-none"
                @click="getEffortAnalysisPDF"
              >
                Generate
              </button>
              <button :disabled="true" v-if="progressButton" class="progress-button b-none">
                PDF Report generating...
              </button>
              <div class="flex flex-wrap" v-if="downloadButton">
                <button class="download-button b-none" @click="downloadPDF">Download</button>
                <div class="flex">
                  <img :src="require(`../assets/pdf.svg`)" class="pdf-icon" />
                  <h2 class="report-text">Effort Analysis Report.PDF</h2>
                </div>
                <button
                  v-if="regenerateButton"
                  class="regenerate-button b-none"
                  @click="regeneratePDF"
                >
                  Regenerate
                </button>
              </div>
              <div v-if="showRefreshLink" class="flex">
                <img :src="require(`../assets/refresh.svg`)" class="refresh-icon" />
                <a class="refresh-status" @click="refreshStatus">Refresh status</a>
              </div>
            </div>
            <div class="para-box">
              <text class="ea-note">Note : </text>
              <p class="ea-para space-break">{{ text }}</p>
              <br />
            </div>
            <br />
            <div v-if="existingReports.length != 0" class="report-box">
              <text class="ea-note" style="text-decoration: underline"
                >Previously generated reports :
              </text>
              <table>
                <thead>
                  <tr>
                    <th style="padding-left: 13px">Start Date</th>
                    <th style="text-align: center">End Date</th>
                    <th style="text-align: center">Download pdf</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, index) in existingReports" :key="index">
                    <td style="padding-left: 13px">{{ i.StartDate }}</td>
                    <td style="text-align: center">{{ i.EndDate }}</td>
                    <td style="text-align: center">
                      <button
                        class="download-button b-none"
                        @click="downloadSinglePDF(i.PDFReference)"
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </Modal3>
  </div>
</template>

<script>
import ProfileAvatar from "vue-profile-avatar";
import moment from "moment";
import "@egjs/flicking-plugins/dist/pagination.css";
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
import API from "../Api";
import Modal from "../components/Modal";
import Modal2 from "../components/Model2";
import Modal3 from "../components/Modal3";
import Mobileapi from "../Mobileapi";
import QuestionIconSvg from "../components/QuestionIconSvg.vue";
import EffortSvg from "../components/EffortSvg";
import SpeedSvg from "../components/SpeedSvg";
import AccuracySvg from "../components/AccuracySvg";
import BatchDropdown from "../components/BatchDropdown.vue";

export default {
  data() {
    return {
      existingReports: [],
      userName: "",
      maxDate: "",
      startDateNew: null,
      endDateNew: null,
      customStartDate: moment().weekday(1).subtract(7, "days").format("YYYY-MM-DD"),
      isDateRangeOpen: false,
      differenceOfDays: "",
      customEndDate: moment().weekday(0).format("YYYY-MM-DD"),
      text: "Select the time period and click on Generate button.",
      regenerateButton: false,
      downloadButton: false,
      progressButton: false,
      pdfLink: "",
      generateButton: true,
      showRefreshLink: false,
      selectedTime: "",
      showPdfPopup: false,
      courseList: [],
      batchList: [],
      subjectList: [],
      classList: [],
      showSubjectBatchList: false,
      selectedBatchName: "",
      selectedBatchId: null,
      selectedSubName: null,
      customDateFilter: false,
      showCustomDate: false,
      showStartCalendar: false,
      showEndCalendar: false,
      showRangeCalendar: false,
      isDataNotFound: false,
      showClassSelectionModal: false,
      isSubSelected: false,
      showAvVideo: false,
      isRemarkShow: false,
      startDate: moment(new Date()).subtract(7, "days").format("DD MMM 'YY"),
      endDate: moment(new Date()).format("DD MMM 'YY"),
      tempStartDate: "",
      tempEndDate: "",
      attributes: [
        {
          key: "today",
          highlight: "blue",
          dates: new Date(),
          excludeDates: null,
        },
      ],
      range: {
        start: new Date(),
        end: new Date(),
      },
      plugins: [
        new AutoPlay({
          duration: 10000,
          direction: "NEXT",
          stopOnHover: false,
        }),
        new Pagination({
          type: "bullet",
        }),
      ],
      AvReport: [],
      effortAnalysisResponse: {},
      studentDataIndex: null,
      viewRemarkIndex: null,
      studentFilter: false,
      viewStudentData: false,
      EffortAnalysisData: [],
      EffortAnalysisFilter: [],
      tempEffortAnalysisData: [],
      UpcomingEventData: [],
      effortCourseId: null,
      effortBatchId: null,
      effortClassId: null,
      effortSubId: null,
      isCustomDateErr: false,
      //   showClassChangeOption: false,
      EffortAnalysisSummary: [],
      //   getclass: [],
      analysisPeriod: [
        {
          displayPeriod: "1 Week",
        },
        {
          displayPeriod: "2 Week",
        },
        {
          displayPeriod: "1 Month",
        },
        {
          displayPeriod: "Custom",
        },
      ],
      selectedPeriod: "1 Week",
    };
  },
  // beforeCreate() {
  //   document.querySelector("body").setAttribute("style", "background:#E0E4F0");
  // },
  // beforeUnmount() {
  //   document.querySelector("body").setAttribute("style", "background:#f6f1ec");
  // },
  activated() {
    this.UserData = this.$store.getters.user;
    Mobileapi.getUserProfile((userResponse) => {
      this.userName = userResponse.data.Name ? userResponse.data.Name : "";
    });

    if (this.$route.params.teacherDashCourseId) {
      this.effortCourseId = Number(this.$route.params.teacherDashCourseId);
      this.effortBatchId = Number(this.$route.params.teacherDashBatchId);
      this.effortClassId = Number(this.$route.params.teacherDashClassId);
      this.effortSubId = Number(this.$route.params.teacherDashSubId);
    } else {
      const getEffortAnalysisData = JSON.parse(localStorage.getItem("effortAnalysisData"));
      if (getEffortAnalysisData != null && getEffortAnalysisData !== undefined) {
        this.effortBatchId = getEffortAnalysisData.batchId;
        this.effortCourseId = getEffortAnalysisData.courseId;
        this.effortClassId = getEffortAnalysisData.classId;
        this.effortSubId = getEffortAnalysisData.subjectId;
      }
    }
    const startDate = moment(this.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.endDate).format("YYYY-MM-DD");
    API.getListOfSubjects(this.effortCourseId, (response) => {
      this.subjectList = response.data.data;
    });
    Mobileapi.getCourseBatchSubject(
      {
        AffiliationId: Number(this.UserData.AffiliationId),
      },
      (resp) => {
        const response = resp.Data;
        response.map((item) =>
          item.BatchDetails.map((item2) =>
            this.batchList.push({
              BatchId: item2.CenterId,
              BatchName: item2.Name,
              ClassId: item2.ClassId,
              CourseId: item2.CourseId,
            }),
          ),
        );
        const selectedBatch = this.batchList.filter((item) => item.BatchId === this.effortBatchId);
        this.selectedBatchName = selectedBatch[0].BatchName;
        this.selectedBatchId = selectedBatch[0].BatchId;
        this.getEffortAnalysisData(
          this.selectedBatchId,
          this.effortCourseId,
          this.effortClassId,
          this.effortSubId,
          startDate,
          endDate,
        );
      },
    );
    this.getAvReport();
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    API.liveclassdata(
      {
        upcoming: 1,
      },
      (response) => {
        if (response.length > 0) {
          this.UpcomingEventData = response.filter(
            (item) => moment(item.StartTime).format("YYYY-MM-DD") === todayDate,
          );
        }
      },
    );
    const user = this.$store.getters.user;
    this.userDetails = user;
    Mobileapi.getClass(
      {
        UserId: this.userDetails.UserId,
        isPractice: 1,
      },
      (response1) => {
        if (response1.data !== null && response1.data.length > 0) {
          this.getclass = response1.data;
          if (this.getclass.length > 1) {
            this.showClassChangeOption = true;
          }
        }
      },
    );
  },
  methods: {
    showCalendar() {
      this.isDateRangeOpen = true;
      this.maxDate = moment(this.customStartDate).add(59, "days").format("YYYY-MM-DD");
      this.generateButton = true;
      this.progressButton = false;
      this.downloadButton = false;
      this.regenerateButton = false;
      this.showRefreshLink = false;
      this.text = "Select the time period and click on Generate button.";
    },
    optionSelected() {
      this.generateButton = true;
      this.progressButton = false;
      this.downloadButton = false;
      this.regenerateButton = false;
      this.showRefreshLink = false;
      this.text = "Select the time period and click on Generate button.";
    },
    getEffortAnalysisPDF() {
      if (this.selectedTime === "last week") {
        this.startDateNew = moment().weekday(1).subtract(7, "days").format("YYYY-MM-DD");
        this.endDateNew = moment().weekday(0).format("YYYY-MM-DD");
      } else if (this.selectedTime === "last month") {
        this.startDateNew = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
        this.endDateNew = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
      } else if (this.selectedTime === "custom") {
        this.startDateNew = moment(this.customStartDate).format("YYYY-MM-DD");
        this.endDateNew = moment(this.customEndDate).format("YYYY-MM-DD");
        const beginDate = moment(this.startDateNew, "YYYY-MM-DD");
        const endingDate = moment(this.endDateNew, "YYYY-MM-DD");
        this.differenceOfDays = endingDate.diff(beginDate, "days");
        if (this.differenceOfDays < 0) {
          window.M.toast({
            html: "End Date can not be less than Start Date",
          });
          return;
        }
        if (this.differenceOfDays > 60) {
          window.M.toast({
            html: "Select Date Range Between 60 days.",
          });
          return;
        }
      }
      Mobileapi.getEffortAnalysisPdf(
        {
          affiliationId: Number(this.UserData.AffiliationId),
          startDate: this.startDateNew,
          endDate: this.endDateNew,
        },
        (response) => {
          if (response.responseCode === 200) {
            if (response.message === "No records found") {
              this.progressButton = true;
              this.generateButton = false;
              this.showRefreshLink = true;
              this.text =
                "Report will be generated in 2-5 mins. Please revisit the page or click on refresh status after some time";
              Mobileapi.createEffortAnalysisPdf(
                {
                  affiliationId: Number(this.UserData.AffiliationId),
                  startDate: this.startDateNew,
                  endDate: this.endDateNew,
                },
                (resp) => {
                  // console.log(resp);
                  if (resp !== "Message sent for processing") {
                    this.text =
                      "Some error has occured. Kindly reach out to SpeEdLabs Representative.";
                  }
                },
              );
            } else if (response.data[0].Status === "In Progress") {
              this.progressButton = true;
              this.generateButton = false;
              this.showRefreshLink = true;
              this.text =
                "Report will be generated in 2-5 mins. Please revisit the page or click on refresh status after some time";
            } else {
              this.regenerateButton = true;
              this.downloadButton = true;
              this.generateButton = false;
              this.showRefreshLink = false;
              this.text =
                "Report has been generated. To download the report, click on Download button. To regenerate the report, click on Regenerate button.";
              this.pdfLink = response.data[0].PDFReference;
            }
          } else {
            this.text = "Some error has occured. Kindly reach out to SpeEdLabs Representative.";
          }
        },
      );
    },
    refreshStatus() {
      Mobileapi.getEffortAnalysisPdf(
        {
          affiliationId: Number(this.UserData.AffiliationId),
          startDate: this.startDateNew,
          endDate: this.endDateNew,
        },
        (response) => {
          if (response.responseCode === 200) {
            if (response.data[0].Status === "Completed") {
              this.generateButton = false;
              this.regenerateButton = true;
              this.downloadButton = true;
              this.text =
                "Report has been generated. To download the report, click on Download button. To regenerate the report, click on Regenerate button.";
              this.progressButton = false;
              this.showRefreshLink = false;
              this.pdfLink = response.data[0].PDFReference;
            }
          } else {
            this.text = "Some error has occured. Kindly reach out to SpeEdLabs Representative.";
          }
        },
      );
    },
    downloadPDF() {
      const link = document.createElement("a");
      link.href = this.pdfLink;
      link.download = "EffortAnalysisReport.pdf";
      link.click();
    },
    downloadSinglePDF(pdfLink) {
      const link = document.createElement("a");
      link.href = pdfLink;
      link.download = "EffortAnalysisReport.pdf";
      link.click();
    },
    regeneratePDF() {
      Mobileapi.deleteEffortAnalysisPdf(
        {
          affiliationId: Number(this.UserData.AffiliationId),
          startDate: this.startDateNew,
          endDate: this.endDateNew,
        },
        (response) => {
          if (response.responseCode === 200) {
            if (response.message === "Success") {
              this.progressButton = true;
              this.downloadButton = false;
              this.regenrateButton = false;
              this.generateButton = false;
              this.showRefreshLink = true;
              this.text =
                "Report will be generated in 2-5 mins. Please revisit the page or click on refresh status after some time";
              Mobileapi.createEffortAnalysisPdf(
                {
                  affiliationId: Number(this.UserData.AffiliationId),
                  startDate: this.startDateNew,
                  endDate: this.endDateNew,
                },
                () => {},
              );
            } else {
              this.text = "Some error has occured. Kindly reach out to SpeEdLabs Representative.";
            }
          } else {
            this.text = "Some error has occured. Kindly reach out to SpeEdLabs Representative.";
          }
        },
      );
    },
    getAvReport() {
      Mobileapi.getAvReportdata(
        {
          CourseId: this.effortCourseId,
          BatchId: this.selectedBatchId ? this.selectedBatchId : this.effortBatchId,
          SubjectId: this.effortSubId,
        },
        (response) => {
          if (response.responseCode === 200 && response.data.length > 0) {
            this.AvReport = response.data;
          }
        },
      );
    },
    onClickOutside() {
      this.showSubjectBatchList = false;
    },
    // changeClass() {
    //   const user = this.$store.getters.user;
    //   this.userDetails = user;
    //   Mobileapi.getClass({
    //     UserId: this.userDetails.UserId,
    //     isPractice: 1,
    //   }, (response1) => {
    //     if (response1.data !== null && response1.data.length > 0) {
    //       this.classList = response1.data;
    //       if (this.classList.length > 1) {
    //         this.showClassSelectionModal = true;
    //       }
    //     }
    //   });
    // },
    getEffortAnalysisData(batchId, courseId, classId, subjectId, startDate, endDate) {
      this.studentDataIndex = null;
      const effortAnalysisData = {
        batchId,
        courseId,
        classId,
        startDate,
        endDate,
        subjectId,
      };
      localStorage.setItem("effortAnalysisData", JSON.stringify(effortAnalysisData));
      const getEffortAnalysisData = JSON.parse(localStorage.getItem("effortAnalysisData"));
      this.$store.dispatch("showLoader", true);
      Mobileapi.getEffortAnalysisData(
        {
          batchId: getEffortAnalysisData.batchId,
          courseId: getEffortAnalysisData.courseId,
          classId: getEffortAnalysisData.classId,
          startDate: getEffortAnalysisData.startDate,
          endDate: getEffortAnalysisData.endDate,
          isMobile: 0,
        },
        (resp) => {
          this.effortAnalysisResponse = resp.data;
          if (
            resp.responseCode === 200 &&
            (resp.data.length > 0 || Object.keys(resp.data).length > 0)
          ) {
            this.subjectList = resp.data.subjects;
            this.tempEffortAnalysisData = resp.data.cards ? resp.data.cards : [];
            this.EffortAnalysisData = this.tempEffortAnalysisData.map((item1) => ({
              ...item1[0],
              activityList: item1.activityList,
              userId: item1.userId,
              userName: item1.userName,
            }));
            this.selectedSubName = "All";
            this.isSubSelected = true;
            this.EffortAnalysisFilter = resp.data.filters ? Object.values(resp.data.filters) : {};
            this.EffortAnalysisSummary = resp.data.summary;
            this.isDataNotFound = false;
            if (this.EffortAnalysisFilter.length > 0) {
              // this.filterByToppers();
              this.filterByStudent(this.EffortAnalysisFilter[0]);
            }
            this.$store.dispatch("showLoader", false);
          } else {
            this.isDataNotFound = true;
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    // formatStartOn(value) {
    //   console.log("value: ", value);
    //   // const newvalue = value.toISOString();
    //   // console.log("iso: ", newvalue);
    //   if (value) {
    //     return moment(value).format("DD MMM 'YY . hh:mm A");
    //   }
    //   return "";
    // },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD MMM 'YY");
      }
      return "";
    },
    formatTime1(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("hh:mm A");
      }
      return "";
    },
    endTime(startTime, endTime) {
      return new Date(
        new Date(`1970/01/01 ${startTime.substring(11, 16)}`).getTime() + endTime * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    formateSubject(value) {
      const data = value?.split(" | ");
      const dataLength = data?.length;
      if (data?.length > 2) {
        return `${data[0]}, ${data[1]} + ${dataLength - 2}`;
      }
      return data?.join(", ");
    },
    selectBatch(selectedBatch) {
      const batchData = {
        BatchName: selectedBatch.label,
        BatchId: selectedBatch.id,
        ClassId: selectedBatch.classId,
        CourseId: selectedBatch.actualCourseId,
      };
      this.isDataNotFound = false;
      this.selectedPeriod = "1 Week";
      this.selectedBatchName = batchData.BatchName;
      this.selectedBatchId = batchData.BatchId;
      this.getAvReport();
      this.showSubjectBatchList = false;
      this.effortCourseId = batchData.CourseId;
      this.startDate = moment(new Date()).subtract(7, "days").format("DD MMM 'YY");
      this.endDate = moment(new Date()).format("DD MMM 'YY");
      this.tempStartDate = moment(this.startDate).format("YYYY-MM-DD");
      this.tempEndDate = moment(this.endDate).format("YYYY-MM-DD");
      if (batchData.ClassId) {
        this.customDateFilter = false;
        this.showCustomDate = false;
        this.showRangeCalendar = false;
        this.effortClassId = batchData.ClassId;
        this.$store.dispatch("showLoader", true);
        API.getListOfSubjects(this.effortCourseId, (response) => {
          this.subjectList = response.data.data;
          this.$store.dispatch("showLoader", false);
          this.getEffortAnalysisData(
            this.selectedBatchId,
            this.effortCourseId,
            this.effortClassId,
            this.effortSubId,
            this.tempStartDate,
            this.tempEndDate,
          );
        });
      } else {
        this.$store.dispatch("showLoader", true);
        Mobileapi.getClassforbatch(
          {
            BatchId: this.selectedBatchId,
          },
          (resp) => {
            this.classList = resp.data;
            this.$store.dispatch("showLoader", false);
            this.$store.dispatch("showLoader", true);
            API.getListOfSubjects(this.effortCourseId, (response) => {
              this.subjectList = response.data.data;
              this.$store.dispatch("showLoader", false);
              if (this.classList.length === 1) {
                this.effortClassId = this.classList[0].id || this.classList[0].Id;
                const effortAnalysisData = JSON.parse(localStorage.getItem("effortAnalysisData"));
                effortAnalysisData.classId = this.effortClassId;
                localStorage.setItem("effortAnalysisData", JSON.stringify(effortAnalysisData));
                this.showClassSelectionModal = false;
                this.getEffortAnalysisData(
                  this.selectedBatchId,
                  this.effortCourseId,
                  this.effortClassId,
                  this.effortSubId,
                  this.tempStartDate,
                  this.tempEndDate,
                );
              } else {
                this.showClassSelectionModal = true;
              }
            });
          },
        );
      }
    },
    onClassChange(classId) {
      this.effortClassId = classId;
      const effortAnalysisData = JSON.parse(localStorage.getItem("effortAnalysisData"));
      effortAnalysisData.classId = this.effortClassId;
      localStorage.setItem("effortAnalysisData", JSON.stringify(effortAnalysisData));
      this.showClassSelectionModal = false;
      this.getEffortAnalysisData(
        this.selectedBatchId,
        this.effortCourseId,
        this.effortClassId,
        this.effortSubId,
        this.tempStartDate,
        this.tempEndDate,
      );
    },
    filterByPeriod(periodName) {
      this.selectedPeriod = periodName;
      if (periodName === "1 Week") {
        this.customDateFilter = false;
        this.showCustomDate = false;
        this.startDate = moment(new Date()).subtract(7, "days").format("DD MMM 'YY");
        this.endDate = moment(new Date()).format("DD MMM 'YY");
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        this.getEffortAnalysisData(
          this.selectedBatchId,
          this.effortCourseId,
          this.effortClassId,
          this.effortSubId,
          startDate,
          endDate,
        );
      }
      if (periodName === "2 Week") {
        this.customDateFilter = false;
        this.showCustomDate = false;
        this.startDate = moment(new Date()).subtract(14, "days").format("DD MMM 'YY");
        this.endDate = moment(new Date()).format("DD MMM 'YY");
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        this.getEffortAnalysisData(
          this.selectedBatchId,
          this.effortCourseId,
          this.effortClassId,
          this.effortSubId,
          startDate,
          endDate,
        );
      }
      if (periodName === "1 Month") {
        this.customDateFilter = false;
        this.showCustomDate = false;
        this.startDate = moment(new Date()).subtract(1, "months").format("DD MMM 'YY");
        this.endDate = moment(new Date()).format("DD MMM 'YY");
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        this.getEffortAnalysisData(
          this.selectedBatchId,
          this.effortCourseId,
          this.effortClassId,
          this.effortSubId,
          startDate,
          endDate,
        );
      }
      if (periodName === "Custom") {
        this.customDateFilter = true;
        this.showCustomDate = true;
        if (this.showRangeCalendar) {
          this.showRangeCalendar = false;
        }
        if (this.showStartCalendar) {
          this.showStartCalendar = false;
        }
        if (this.showEndCalendar) {
          this.showEndCalendar = false;
        }
      }
    },
    selectStartDate(day) {
      const dayId = day.id;
      this.startDate = moment(dayId).format("DD MMM 'YY");
      this.attributes.dates = this.startDate;
      this.showEndCalendar = false;
      this.showStartCalendar = false;
    },
    selectEndDate(day) {
      const dayId = day.id;
      this.endDate = moment(dayId).format("DD MMM 'YY");
      this.showEndCalendar = false;
      this.showStartCalendar = false;
      if (this.startDate && this.endDate) {
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        if (startDate < endDate || startDate === endDate) {
          this.getEffortAnalysisData(
            this.selectedBatchId,
            this.effortCourseId,
            this.effortClassId,
            this.effortSubId,
            startDate,
            endDate,
          );
          this.isCustomDateErr = false;
        } else {
          this.isCustomDateErr = true;
        }
      }
    },
    selectDateRange() {
      this.startDate = moment(this.range.start).utc().format("DD MMM 'YY");
      this.endDate = moment(this.range.end).utc().format("DD MMM 'YY");
      if (this.startDate !== this.endDate) {
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        this.getEffortAnalysisData(
          this.selectedBatchId,
          this.effortCourseId,
          this.effortClassId,
          this.effortSubId,
          startDate,
          endDate,
        );
        this.showRangeCalendar = false;
      }
      this.range.start = new Date();
      this.range.end = new Date();
    },
    filterByStudent(effortData) {
      const filter = Object.values(effortData.order).flat();
      const sortArray = (arr1, arr2) => {
        arr2.sort((a, b) => {
          const aKey = a.userId;
          const bKey = b.userId;
          return arr1.indexOf(aKey) - arr1.indexOf(bKey);
        });
      };
      sortArray(filter, this.EffortAnalysisData);
      this.EffortAnalysisFilter = this.EffortAnalysisFilter.map((item) => {
        if (item.displayName === effortData.displayName) {
          return {
            ...item,
            isDefault: true,
          };
        }
        return {
          ...item,
          isDefault: false,
        };
      });
    },
    allSubject() {
      this.selectedSubName = null;
      this.EffortAnalysisData = this.tempEffortAnalysisData;
      this.isSubSelected = false;
    },
    selectSubject(subjectName, subjectId) {
      this.effortSubId = subjectId;
      this.selectedSubName = subjectName;
      this.isSubSelected = true;
      this.EffortAnalysisData = this.tempEffortAnalysisData.map((item1) => ({
        ...item1[subjectId],
        activityList:
          subjectName === "All"
            ? item1.activityList
            : item1.activityList.filter((item2) => item2.subjectNames === subjectName),
        userId: item1.userId,
        userName: item1.userName,
      }));
    },
    showStudentData(dataIndex) {
      if (this.studentDataIndex !== dataIndex) {
        this.studentDataIndex = dataIndex;
        this.viewRemarkIndex = null;
      } else {
        this.studentDataIndex = null;
      }
    },
    viewReport(userSessionId, userName) {
      this.$router.push({
        name: "PracticeSessionNewReport",
        params: {
          UserTestSessionId: userSessionId,
          Name: userName,
          TestName: "EffortAnalysis",
        },
      });
    },
    showRemark(reportIndex) {
      if (this.viewRemarkIndex !== reportIndex) {
        this.viewRemarkIndex = reportIndex;
        this.isRemarkShow = true;
      } else {
        this.viewRemarkIndex = null;
        this.isRemarkShow = false;
      }
    },
    watchVideo(videoUrl) {
      this.avVideoUrl = videoUrl;
      this.showAvVideo = true;
    },
    showPdf() {
      this.showPdfPopup = true;
      this.showRefreshLink = false;
      this.progressButton = false;
      this.downloadButton = false;
      this.regenerateButton = false;
      this.isButtonDisabled = false;
      this.generateButton = true;
      this.selectedTime = "last week";
      this.customStartDate = moment().weekday(1).subtract(7, "days").format("YYYY-MM-DD");
      this.customEndDate = moment().weekday(0).format("YYYY-MM-DD");
      this.text = "Select the time period and click on Generate button.";
      Mobileapi.getExistingPdf(
        { affiliationId: Number(this.UserData.AffiliationId) },
        (response) => {
          this.existingReports = response.data;
        },
      );
    },
    scheduleClass() {
      this.$router.push({ path: "/affiliation/create-live-class" });
    },
    CreateAssignment() {
      this.$router.push({ name: "CreateAssignment" });
    },
    createTest() {
      const createInstituteTestParams = {
        courseIdProps: this.effortCourseId,
        batchIdProps: this.selectedBatchId ? this.selectedBatchId : this.effortBatchId,
        subIdProps: this.effortSubId,
        classIdProps: this.effortClassId,
      };
      localStorage.setItem("CreateInstituteTestParams", JSON.stringify(createInstituteTestParams));
      this.$router.push({ name: "CreateInstituteTest", params: createInstituteTestParams });
    },
    batchValueChanged(event) {
      if (event != null && event.length > 0) {
        this.selectedBatchId = Number(event[0]);
      }
    },
    batchNameChanged(event) {
      if (event != null) {
        this.selectedBatchId = Number(event.id);
        this.selectedBatchName = String(event.label);
        this.selectBatch(event);
      }
    },
  },
  components: {
    ProfileAvatar,
    Flicking,
    Modal,
    Modal2,
    Modal3,
    QuestionIconSvg,
    EffortSvg,
    SpeedSvg,
    AccuracySvg,
    BatchDropdown,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");

.flicking-viewport {
  padding-bottom: 2.5rem !important;
}
.effort-analysis {
  font-family: "Open Sans" !important;
  margin-top: 25px;
}
select {
  display: block;
}

/* Custom CSS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /*Standard */
  margin: 0;
}

* {
  box-sizing: border-box;
}

.dark-text {
  color: #333333 !important;
}

.black-text {
  color: #000000 !important;
}

.skyblue-text {
  color: #3751ff !important;
}

.card-border {
  border: 1px solid #e3e3e3;
}

.card-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.card-radius {
  border-radius: 5px;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-11 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.pointer {
  cursor: pointer;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.coveragebuttons {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  outline: none;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
}

.gray-btn {
  display: inline-block;
  height: 21px;
  background: #f8f8f8;
  border: 1px solid #d6daf7;
  border-radius: 4px;
  font-weight: 400;
  white-space: nowrap;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  opacity: 0.65;
  white-space: nowrap;
  color: #000000;
  padding-left: 10px;
  max-width: 100px;
  padding-right: 10px;
}

/* Custom CSS */
/* Left Section */
.left-section {
  max-width: 365px;
  min-width: 310px;
  padding: 15px 20px;
  height: max-content;
  margin-bottom: 10px;
}

.left-card {
  margin-top: 20px;
  margin-bottom: 12px;
}

.secondary-header {
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #642c90;
}

.subject-select {
  width: 268px;
  height: 67px;
  position: relative;
  margin-top: 12px;
  padding: 10px 16px;
}

.calender-box {
  margin-top: 12px;
  z-index: 10;
}

.subject-select .subject-img {
  position: absolute;
  right: 17px;
  top: 13px;
  opacity: 0.1;
  width: 55px;
}

.subject-list {
  width: 100%;
  max-height: 229px;
  position: absolute;
  left: 0;
  z-index: 20;
  top: -1px;
  background: #ffffff;
  border: 1px solid #333333;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.15);
  border-radius: 5px;
  padding: 10px 0;
}

.subject-list a {
  padding-left: 15px;
  margin-bottom: 10px;
}

.sublist p:first-child(1) {
  margin-top: 10px;
}

.sublist p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 8px 15px;
}

.sublist p:hover {
  background: #f6f8fb;
}

.analysis-period .gray-btn.active,
.student-filter .gray-btn.active,
.subject-filter .gray-btn.active {
  opacity: 1;
  font-weight: 600;
  background: #f0f2ff;
}

.progress-value {
  margin-top: 14px;
  margin-bottom: 8px;
}

.progress1 {
  position: relative;
  /* max-width: 100%; */
  width: 126px;
  height: 6px;
  background: #f2f3f8;
  border-radius: 30px;
}

.progress-bar1 {
  position: absolute;
  height: 100%;
  border-radius: 30px;
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: #ee9b05;
}

/*Middle section*/
.middle-section {
  max-width: 575px;
  min-width: 300px;
  width: 100%;
  margin: 0 20px;
  margin-bottom: 20px;
}

.data-header h3 {
  max-width: 100%;
  width: 177px;
}

.inner-box {
  max-width: 484px;
  width: 100%;
  background: white;
  padding: 10px;
  margin-bottom: 15px;
}

.inner-box:last-child {
  margin-bottom: 0;
}

.inner-box .report-btn {
  opacity: 0;
  visibility: hidden;
}

.inner-box:hover .report-btn {
  opacity: 1;
  visibility: visible;
}

.data-contents {
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  width: 100%;
  padding: 15px 0 0;
  margin-top: 15px;
}

.data-contents.active {
  border: 1px solid #333333;
}

.data-contents .user-card {
  position: relative;
}

.data-contents .user-card.active::before {
  position: absolute;
  content: "";
  left: -15px;
  top: 4px;
  background: #3751ff;
  margin-top: -4.15px;
  border-radius: 0px 2px 2px 0px;
  width: 3px;
  height: 20px;
}

.data-contents .view-btn {
  opacity: 0;
  visibility: hidden;
}

.data-contents:hover .view-btn {
  opacity: 1;
  visibility: visible;
}

.remark-box {
  padding: 10px;
  margin-top: 15px;
  max-width: 464px;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
}

.remark-box p {
  white-space: break-spaces;
}

/*Right Section */
.right-section {
  width: 216px;
  margin-bottom: 10px;
}

.recommendation {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 16px 15px;
  margin-bottom: 15px;
  width: 100%;
}

.recommendation .Plotly {
  /* font-size: 55px; */
  margin-top: 15px;
}

.recommendation:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.recommendation-content {
  margin-top: 15px;
}

.recommendation-content .main-recommendation-box {
  width: 170px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar1.yellow {
  background: yellow;
}

.progress-bar1.blue {
  background: blue;
}

.progress-bar1.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.summaryHeader {
  padding-top: 8px;
  padding-bottom: 8px;
}

.summaryBody {
  padding-bottom: 15px;
}

/*Leaderboard */
.right-section-details {
  min-width: 216px;
  margin-right: 15px;
}

.schedule-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 15px 15px 15px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 20px;
}

.schedule-card img {
  width: 144px;
  height: 101px;
}

.schedule-card .coveragebuttons {
  width: 187px !important;
  height: 28px !important;
  margin-top: 10px;
  border-radius: 5px;
  line-height: 28px;
}

.schedule-card .coveragebuttons:nth-child(2) {
  background: #f3f5fc !important;
  color: #3751ff !important;
}

.schedule-card .coveragebuttons:last-child {
  background: #3751ff !important;
}

.upcoming-event {
  max-height: 300px;
}

.event-card:first-child {
  margin-top: 0;
}

.event-card img {
  width: 27px;
  height: 27px;
  margin-right: 9.5px;
}

.event-card {
  width: 216px;
  height: 64px;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #e0e4f0;
  transition: 0.3s all ease;
}

.event-card:hover {
  border: 1px solid #000000;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  color: white !important;
  margin-right: 10px;
  margin-left: 0 !important;
  line-height: 20px;
}

.data-contents:nth-child(even) .leader-avatar {
  background-color: #69b0dd !important;
}

.data-contents:nth-child(odd) .leader-avatar {
  background-color: #fc8686 !important;
}

.leaderboard .leader-body {
  max-height: 390px;
}

.leader-card {
  padding: 22px 15px;
  position: relative;
  max-height: 433px;
}

.right-section-details {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  background: #fc8686 !important;
  color: white !important;
  margin-right: 10px;
}

.right-section-details {
  min-width: 216px;
}

.leaderboard-content {
  padding: 16px 15px 4px;
  max-height: 470px;
  height: 100%;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
}

/*Coverage report*/
.slideshow-container {
  min-width: 100%;
  background: #5a5fe6;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 5px;
  min-width: auto;
  padding: 2px 0;
  height: 125px;
  overflow: hidden;
}

.class-image {
  position: absolute;
  right: -10px;
  top: 60px;
  z-index: 0;
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.video-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.video-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.videoModal {
  width: 600px;
  height: 340px;
  padding: 12px 11px 5px;
}
.pdf-box {
  box-sizing: border-box;
  position: absolute;
  max-width: 651px;
  left: 398px;
  top: 184px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 15px;
}
.title-effort-analysis {
  height: 22px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3751ff;
  margin-top: 23px;
  margin-left: 24px;
}
.close-button {
  width: 32px;
  height: 32px;
  margin-top: 18px;
  margin-right: 17px;
}
.select-period {
  height: 16px;
  width: 76px;
  margin-top: 26px;
  margin-left: 24px;
  margin-bottom: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.65);
}
.select-custom-date {
  height: 11.3px;
  width: 76px;
  margin-top: 26px;
  margin-bottom: 14px;
  margin-left: 24px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 11.3px;
  color: rgba(51, 51, 51, 0.65);
}
.select-date-box {
  height: 35px !important;
  width: 130px;
  margin-left: 40px;
  border: 1px solid #e0e4f0 !important;
  border-radius: 5px !important;
  margin-right: 10px;
}
.select-period-box {
  height: 36px;
  width: 268px;
  margin-left: 24px;
  box-sizing: border-box;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  margin-right: 10px;
}
.generate-button {
  background: #ee9b05;
  margin-top: 18px;
  margin-left: 24px;
  margin-bottom: 30px;
  border-radius: 5px;
  width: 100px;
  height: 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.regenerate-button {
  background: #ee9b05;
  margin-top: 18px;
  margin-left: 24px;
  margin-bottom: 30px;
  border-radius: 5px;
  width: 100px;
  height: 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.progress-button {
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 6px;
  padding-right: 9px;
  background: rgba(238, 155, 5, 0.5);
  margin-top: 18px;
  margin-left: 24px;
  margin-bottom: 30px;
  border-radius: 5px;
  width: 156px;
  height: 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-right: 13.34px;
}
.refresh-status {
  height: 16px;
  width: 85px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5c8eff;
  margin-right: 351px;
  margin-bottom: 36px;
  display: block;
  margin-left: 8px;
  margin-top: 24px;
}
.refresh-icon {
  width: 13.32px;
  height: 13.33px;
  margin-top: 24px;
}
.pdf-icon {
  width: 12px;
  height: 16px;
  margin-top: 24px;
  margin-right: 6px;
}
.download-button {
  width: 100px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 21px;
  height: 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 18px;
  margin-left: 24px;
  margin-bottom: 30px;
  margin-right: 18px;
  background: #37841c;
  border-radius: 5px;
}
.report-text {
  margin-top: 24px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

.para-box {
  box-sizing: border-box;
  margin-left: 24px;
  background: #fafafa;
  height: 90px;
  margin-right: 17px;
  max-width: 610px;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
}
.report-box {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  white-space: nowrap !important;
  box-sizing: border-box;
  margin-left: 24px;
  background: #fafafa;
  height: 206px;
  margin-right: 17px;
  max-width: 610px;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
}
.ea-note {
  height: 25px;
  width: 41px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #333333;
  padding-top: 10px;
  padding-left: 13px;
  padding-bottom: 7px;
}
.ea-para {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #333333;
  padding-left: 13px;
  padding-right: 13px;
}
/*Media Query*/
@media screen and (max-width: 1335px) {
  .progress1 {
    width: 100%;
    margin-left: 30px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1250px) {
  .progress1 {
    width: 126px;
    margin-left: 30px;
    margin-top: 0px;
  }

  .data-contents .coveragebuttons {
    margin-top: 0px;
  }

  .effort-analysis {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    max-width: 565px;
    width: 95%;
    margin-bottom: 15px;
  }

  .middle-section {
    width: 95%;
  }
}

@media screen and (max-width: 545px) {
  .progress1 {
    width: 100%;
    margin-left: 30px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 476px) {
  .metrics {
    flex-wrap: wrap;
  }

  .inner-box-detail {
    flex-wrap: wrap;
  }

  .user-subject {
    margin-left: 30px !important;
  }
}

@media screen and (max-width: 395px) {
  .speedSvg {
    width: 55px !important;
  }

  .metrics {
    gap: 10px !important;
    padding-bottom: 10px !important;
  }

  .summaryBody {
    padding-left: 8% !important;
  }
}

.flicking-camera > * {
  width: 100% !important;
}

.modal-button {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #642c90;
  border-radius: 6px;
  color: #333;
  font-size: 16px;
  font-weight: 700;
}

.modal-button:hover {
  background-color: #f4e7ff;
}

.student-btn {
  margin-bottom: 10px;
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.no-record-box {
  width: 100%;
  /* height: 100px; */
  /* display: flex; */
  border: 10px solid rgb(241, 243, 255);
  background-color: white;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .close-button {
    margin-right: 0px;
  }
  .flex-class {
    flex-direction: column;
  }
  .select-period-box {
    width: 100%;
    margin-left: 0px;
  }

  .select-date-box {
    margin-left: 0px;
    width: 100%;
  }

  .download-button {
    margin-left: 0px;
    margin-bottom: 0;
  }
  .para-box,
  .report-box {
    margin: 0px;
  }
  .regenerate-button,
  .progress-button,
  .generate-button,
  .select-custom-date,
  .select-period,
  .title-effort-analysis {
    margin-left: 0px;
  }
  .report-text {
    margin-right: 24px;
    margin-left: 0px;
  }
}
</style>
